import { css, createGlobalStyle } from 'styled-components'
import { media } from './utils'

import globalType from './type'
import globalColours from './colours'

export const GlobalStyles = createGlobalStyle`
    html,
    body {
        margin: 0;
        padding: 0;
        /*height: 100%;*/
        width: 100%;
        background: ${globalColours.lightGreen};
        color: black;
        font-family: 'Roobert', sans-serif;
    }
    
    a {
		text-decoration: none;
        color: inherit;
        font-size: inherit;
    }

    h1, h2, h3 {
        font-size: 1rem;
        font-weight: 300;
        margin: 0;
    }

    a, p, div {
        ${globalType.body};
        letter-spacing: 0.15px;
        font-weight: 300;
    }

    b, strong {
        font-weight: 300;
		font-family: 'Souvenir', serif;
    }

    * {
        -webkit-overflow-scrolling: touch;
        -webkit-font-smoothing: antialiased;
        box-sizing: border-box;
    }

    p:first-child {
		margin-top: 0;
	}

    /* Next Wrapper */

    #__next {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
`

export const wrapper = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    height: 100%;
`

export const container = css`
    display: flex;
    width: 100%;
`

export const hoverState = css`
    transition: 0.15s opacity ease;

    &:hover {
        opacity: 0.7;
    }
`

export const padding = css`
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;

    ${media.tablet`
		padding-left: 16px; 
		padding-right: 16px;
	`}
`

export const bgImage = css`
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
`

export const bgIcon = css`
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
`

export const button = css`
    border: 3px solid;
    box-sizing: border-box;
    border-radius: 22px;
    padding: 16px 28px;
    font-size: 36px;
    line-height: 1.1;
    background: transparent;
    width: fit-content;

    display: flex;
    align-items: center;

    ${media.phone`
		font-size: 26px;
		padding: 12px 20px;
		border: 2px solid;
	`}

    &::before {
        content: '';
        display: none;
        background-image: url(${require('../assets/svg/arrow-right.svg')});
        width: 18px;
        height: 24px;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        padding: 0 4px;
        margin-right: 12px;
    }

    /*&:visited {
		color: inherit;
	}*/

    &:hover {
        background: ${globalColours.purple};
        cursor: pointer;

        &:before {
            display: inline-block;
        }
    }
`

export const buttonSmall = css`
	box-sizing: border-box;
	padding: 12px 24px;
	font-size: 18px;
	line-height: 1.1;
	/*color: ${globalColours.black};*/
	background: ${globalColours.white};
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
	border-radius: 35px;

	display: flex;
	align-items: center;

	&:hover {
		color: ${globalColours.white};
		background: ${globalColours.black};
		cursor: pointer;
	}
`

// Re-export colours & type

export const colours = globalColours
export const type = globalType
