import { css } from 'styled-components'
import { media } from './utils'

const type = {
    heading1: css`
		font-size: 72px;
		line-height: 1.1;
		
		${media.phone`
			font-size: 36px;
			line-height: 1.2;
		`}
    `,
    heading2: css`
		font-size: 48px;
		line-height: 1.2;
		
		${media.phone`
			font-size: 36px;
			line-height: 1.2;
		`}
    `,
    heading3: css`
		font-size: 36px;
		line-height: 1.2;
		
		${media.phone`
			font-size: 24px;
			line-height: 1.2;
		`}
    `,
    heading4: css`

    `,
    body: css`
        font-size: 36px;
		line-height: 1.2;
		
		${media.phone`
			font-size: 24px;
			line-height: 1.2;
		`}
	`,
	bodySmall: css`
        font-size: 18px;
        line-height: 1.2;
    `,
}

export default type